<template>
  <v-app>
    <v-main :class="$route.params.org_id">
      <v-container
        fill-height
        fluid
        class="portfolio-sharing align-start"
        :class="{ 'align-center pa-0': !showApplicationBar }"
      >
        <v-row class="portfolio-sharing__content">
          <v-col cols="12" md="12">
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify";
import { productDemoThemes } from "@/themes";
import { setupAppConfiguration } from "@/utils/app-config";
import { useAppStore } from "~/store/app";
import { storeToRefs } from "pinia";

const $route = useRoute();
const { $sentry, $setAppLocale } = useNuxtApp();

const themes = productDemoThemes;

const { layoutSettings } = storeToRefs(useAppStore());
const { updateLayoutSettings } = useAppStore();

const showLogo = computed(() => {
  return layoutSettings.value && layoutSettings.value.showLogo;
});
const showLanguageSwitcher = computed(() => {
  return layoutSettings.value && layoutSettings.value.showLanguageSwitcher;
});
const favicon = computed(() => {
  return layoutSettings.value && layoutSettings.value.favicon;
});
const title = computed(() => {
  return layoutSettings.value && layoutSettings.value.name;
});
const showApplicationBar = computed(() => {
  return showLogo.value || showLanguageSwitcher.value;
});

useHead({
  link: [
    {
      hid: "icon",
      rel: "icon",
      type: "image/x-icon",
      href: () => favicon.value,
    },
  ],
  titleTemplate: () => title.value,
});

watch(
  () => $route.fullPath,
  (newVal) => {
    $sentry.setContext({
      path: newVal.fullPath,
    });
  },
);

setup();

function setup() {
  if ($route.params.org_id) {
    switch ($route.params.org_id) {
      case "my-benefit":
        _setupAppConfiguration(themes.MYBENEFIT, "MYBENEFIT");
        break;
      default:
        break;
    }
  }
  $sentry.setContext({
    path: $route.fullPath,
  });
}

function _setupAppConfiguration(appConfig, _theme) {
  const configuration = setupAppConfiguration(appConfig);
  useTheme().global.name.value = _theme;

  if (configuration.store.locale) {
    $setAppLocale(configuration.store.locale);
  }
  updateLayoutSettings({
    ...configuration.store,
  });
}
</script>
<style lang="scss" scoped>
@use "sass:map" as map;
@import "vuetify/_tools";
@import "vuetify/lib/styles/settings/_variables";

.portfolio-sharing {
  &__content {
    z-index: 2;
  }
  &__background-illustration {
    position: absolute;
    width: 540px;
    bottom: 0;
    left: 0;
    z-index: 1 !important;

    &__fade {
      opacity: 0.7;
    }
  }
  &__logo {
    position: absolute;
    bottom: 50%;
    left: 140px;
    z-index: 1 !important;

    &__img {
      width: 440px;
    }
  }
}

@media #{map.get($display-breakpoints, 'xs')} {
  .portfolio-sharing {
    &__background-illustration {
      width: calc(100% - 25vw);
    }
  }
}

@media #{map.get($display-breakpoints, 'xs')} {
  .portfolio-sharing__logo {
    top: 3%;
    left: 3%;
    &__img {
      width: 180px !important;
    }
  }
}

@media #{map.get($display-breakpoints, 'sm')} {
  .portfolio-sharing__logo {
    top: 3%;
    left: 10%;
    &__img {
      width: 240px !important;
    }
  }
}

@media #{map.get($display-breakpoints, 'md')} {
  .portfolio-sharing__logo {
    &__img {
      width: 320px !important;
    }
  }
}
</style>
